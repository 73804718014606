import { Box, IconButton, chakra } from '@chakra-ui/react';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import React, { useContext, useMemo } from 'react';

import Footer from './Footer';
import { useRouter } from 'next/router';

interface ClosePageLayoutProps {
  children: React.ReactNode;
  route: string;
  getBackHref: (currentLang: LanguageContextKey) => string | undefined;
}

const ClosePageLayout: React.FC<ClosePageLayoutProps> = (props) => {
  const { children } = props;
  const router = useRouter();

  const { currentLang } = useContext(LanguageContext);

  const backHref = useMemo(
    () => props.getBackHref(currentLang || 'fr'),
    [currentLang],
  );

  const goBack = () => {
    router.push(backHref || '/', undefined, {
      scroll: false,
    });
  };

  return (
    <Box>
      <IconButton
        position="fixed"
        shadow="sm"
        bgColor="white"
        w={[12, null, null, 10]}
        h={[12, null, null, 10]}
        top={[2, null, 3]}
        right={[2, null, 3]}
        aria-label={'close'}
        onClick={goBack}
        zIndex={2}
      >
        <chakra.span
          color="brand.500"
          className="icon-diadao-cross2"
          fontSize={['20px', null, null, '18px']}
        />
      </IconButton>
      {children}
      <Footer />
    </Box>
  );
};

export default ClosePageLayout;
