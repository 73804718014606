import {
  Box,
  Fade,
  IconButton,
  SlideFade,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import {
  KustomPageComponent,
  RoomListComponentData,
  RoomSpecsComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { $kustomAdminBarHeightVarName } from '@/lib/kustomcms-sdk/lib/admin/components/KustomAuthModal';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import Link from 'next/link';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import { useRouter } from 'next/router';
import useTranslatedString from '@/lib/kustomcms-sdk/lib/hooks/useTranslatedString';

interface RoomLayoutProps {
  route: string;
  children: React.ReactNode;
}

const RoomLayout: React.FC<RoomLayoutProps> = (props) => {
  const { children, route } = props;

  const pagesContext = useContext(PagesContext);
  const router = useRouter();
  const { currentLang } = useContext(LanguageContext);

  const roomsPage = pagesContext.pages.find((page) => page.model === 'rooms');
  const roomsPageHref = useTranslatedString(
    roomsPage?.prettyUrl || { fr: '#' },
  );

  const roomsCmps = roomsPage?.components?.filter(
    (cmp) => cmp.type === 'roomList',
  ) as KustomPageComponent<RoomListComponentData>[];

  const roomsListCmp = roomsCmps?.[0];
  const suitesListCmp = roomsCmps?.[1];

  const [roomsList, suitesList] = useMemo(() => {
    const roomsList = roomsListCmp?.data.activeRoomsId
      .map((roomId) => pagesContext.rooms.find((room) => room._id === roomId))
      .filter((room) => !!room)
      .sort((a, b) => a!.index - b!.index);

    const suitesList = suitesListCmp?.data.activeRoomsId
      .map((roomId) => pagesContext.rooms.find((room) => room._id === roomId))
      .filter((room) => !!room)
      .sort((a, b) => a!.index - b!.index);

    return [roomsList, suitesList];
  }, [roomsListCmp, suitesListCmp]);

  const roomId = pagesContext.routes[route]?._id;

  const sidebarWidth = 440;

  const isSuites =
    suitesListCmp?.data.activeRoomsId.includes(roomId || '') || false;

  const goBack = () => {
    const bt = document.querySelector(
      '.diadao-rooms-sticky-opened .diadao-hsw-qs-roomstickydetail .hsc-button-sticky-rooms-exit.diadao-hsc-sticky-opened-visible',
    ) as HTMLButtonElement;
    if (bt) {
      bt.click();
    }
    router.push(roomsPageHref, undefined, {
      scroll: false,
    });
  };

  return (
    <Box display="flex" minH="100vh" bgColor="accent.100">
      <Box
        w={sidebarWidth}
        display={['none', null, null, 'flex']}
        position="fixed"
        maxH="100vh"
      >
        <Tabs
          display="flex"
          flexDir="column"
          colorScheme="brand"
          defaultIndex={isSuites ? 1 : 0}
          pt={[8, null, 14, null, null, 16]}
          pl={[8, null, 10, null, null, 16]}
          pr={[null, null, 8, null, null, 16]}
          pb={0}
          w={sidebarWidth}
          onChange={(index) => {
            const firstRoom = index === 0 ? roomsList?.[0] : suitesList?.[0];
            router.push(
              firstRoom?.prettyUrl[currentLang || 'fr'] ||
                firstRoom?.prettyUrl.fr ||
                '#',
              undefined,
              {
                scroll: false,
              },
            );
          }}
        >
          <TabList
            borderBottom="none"
            fontFamily="jhaSemiboldItalic"
            color="brand.500"
            position="fixed"
          >
            {['Chambres', 'Suites'].map((tab, index) => (
              <Tab
                key={index}
                pb={0}
                px={1}
                lineHeight={1.3}
                mr={6}
                fontSize={['18px', null, '25px', null, null, '32px']}
                borderBottomWidth="4px"
                _active={{
                  bgColor: 'transparent',
                  borderBottomColor: 'accent.500',
                  textDecorationColor: 'accent.100',
                }}
                _selected={{
                  borderColor: 'accent.500',
                }}
                as="h2"
              >
                {tab}
              </Tab>
            ))}
          </TabList>

          <TabPanels
            overflowY="auto"
            className="ignoreSmoothScroll"
            mt={14}
            pr={[null, null, null, 6, null, null, 10]}
          >
            {[roomsList, suitesList].map((list, index) => (
              <TabPanel key={index} px={0} py={[6, null, 8, null, null, 12]}>
                <VStack align="stretch" spacing={7}>
                  {list?.map((roomPage, index) => {
                    const specsCmp = roomPage?.components[0] as
                      | KustomPageComponent<RoomSpecsComponentData>
                      | undefined;
                    const specs = specsCmp?.data;

                    const href =
                      roomPage?.prettyUrl[currentLang || 'fr'] ||
                      roomPage?.prettyUrl.fr;

                    const isFocused =
                      route === href || route === `${currentLang}/${href}`;

                    return (
                      <SlideFade
                        key={roomPage?._id}
                        offsetY="80px"
                        in
                        delay={(index + 1) / 20}
                      >
                        <Link href={href || '#'}>
                          <Box
                            key={roomPage?._id}
                            opacity={isFocused ? 1 : 0.5}
                            cursor="pointer"
                            role="group"
                            transition="all 0.3s ease"
                            borderLeftWidth={0}
                            paddingRight={'28px'}
                            borderColor={
                              isFocused ? 'accent.500' : 'transparent'
                            }
                            _hover={{
                              borderLeftWidth: '4px',
                              paddingLeft: '5',
                              paddingRight: '0',
                            }}
                            {...(isFocused && {
                              borderLeftWidth: '4px',
                              paddingLeft: '5',
                              paddingRight: '0',
                            })}
                          >
                            <TranslatedString
                              fontFamily="jhaSemibold"
                              lineHeight={1}
                              mb={1}
                              fontSize={[
                                '14px',
                                null,
                                '19px',
                                null,
                                null,
                                '24px',
                              ]}
                              as="h3"
                            >
                              {specs?.title}
                            </TranslatedString>
                            <Text
                              fontFamily="jhaSemiboldItalic"
                              fontSize={[
                                '12px',
                                null,
                                '15px',
                                null,
                                null,
                                '18px',
                              ]}
                              as="p"
                            >
                              {specs?.peoplesNumber} personnes
                            </Text>
                          </Box>
                        </Link>
                      </SlideFade>
                    );
                  })}
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <Box
        ml={[0, null, null, sidebarWidth]}
        pl={[10, null, null, 0]}
        flex="3 1 0"
      >
        <IconButton
          position="fixed"
          shadow="sm"
          bgColor="white"
          w={10}
          h={10}
          top={[2, null, 3]}
          mt={`var(${$kustomAdminBarHeightVarName}, 0)`}
          right={[2, null, 3]}
          aria-label={'close'}
          onClick={goBack}
          fontSize="18px"
          zIndex={1}
        >
          <chakra.span
            _before={{ color: 'brand.500' }}
            className="icon-diadao-cross3"
          />
        </IconButton>
        {children}
      </Box>
    </Box>
  );
};

export default RoomLayout;
