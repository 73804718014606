import React, { useContext } from 'react';
import Head from 'next/head';

import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import PageHeader from './PageHeader';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import { TextImageComponentData } from '@/lib/kustomcms-sdk/lib/types';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';

interface Page404Props {}

const Page404: React.FC<Page404Props> = (props) => {
  const { pages } = useContext(PagesContext);

  const page404 = pages.find((page) => page.pageCode === 'x7s64p');

  const headerCmp = usePageComponent<TextImageComponentData>(
    page404,
    null,
    '16891913910836496',
  );

  const mediaCmp = usePageComponent<TextImageComponentData>(
    page404,
    null,
    '1689191712184569',
  );

  const currentDevice = useResponsiveMediasDevice();

  if (!page404) {
    return <>404</>;
  }

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
        <meta name="bingbot" content="noindex, nofollow" />
      </Head>
      <Box>
        {headerCmp && (
          <PageHeader
            component={headerCmp}
            page={page404}
            style={{
              height: ['250px', null, null, '500px'],
            }}
          />
        )}
        <Box display="flex" justifyContent="center">
          <Link href="/" aria-label="Home">
            {mediaCmp?.data?.media && (
              <ResponsiveMedias
                value={mediaCmp?.data?.media}
                currentDevice={currentDevice}
                style={{
                  background: 'transparent',
                }}
              />
            )}
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Page404;
