import {
  AtomicComponent,
  KustomPage,
  KustomRoutes,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import ClosePageLayout from '@/components/ClosePageLayout';
import Head from 'next/head';
import KustomComponent from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import Page404 from '@/components/Page404';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RoomLayout from '@/components/rooms/RoomLayout';
import { layoutArgs } from '@/next';
import { useRouter } from 'next/router';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';

const page404Id = 'x7s64p';

const DynamicSlugPage = (props: any) => {
  const router = useRouter();
  const pagesContext = useContext(PagesContext);
  const langContext = useContext(LanguageContext);

  const { settings } = useContext(SettingsContext);
  const urlSite = settings?.system?.clientUrl;

  const { locale } = useRouter();
  const language = locale ? (locale as LanguageContextKey) : 'fr';
  const slug = ((router.query.slug || []) as string[])?.join('/');

  const content = useMemo(
    () =>
      pagesContext.pages.find(
        (page) =>
          page.prettyUrl[language] === `${language}/${slug}` ||
          page.prettyUrl[language] === slug,
      ),
    [slug, pagesContext.pages],
  );

  if (!content || slug === '404') {
    return <Page404 />;
  }

  const openGraphTitle = content?.metaTitle?.[langContext.currentLang || 'fr'];
  const openGraphDescription =
    content?.metaDescription?.[langContext.currentLang || 'fr'];

  return (
    <>
      <Head>
        <title>
          {content.metaTitle?.[language || 'fr'] || 'Les Elmes Hotel'}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <meta
          name="description"
          content={content.metaDescription?.[language || 'fr']}
        />
        {(content.category === 'rgpd' ||
          content.pageCode === 'o0otcc' ||
          content.objectCode === 'o0otcc' ||
          content.pageCode === page404Id ||
          content.objectCode === page404Id) && (
          <>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="googlebot" content="noindex, nofollow" />
            <meta name="bingbot" content="noindex, nofollow" />
          </>
        )}
        <link rel="canonical" href={`${urlSite}/${language}/${slug}`} />

        <meta property="og:title" content={openGraphTitle} />
        <meta property="og:description" content={openGraphDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${urlSite}/${language}/${slug}`} />
        <meta
          property="og:image"
          content={`${urlSite}/og-hotel-les-elmes.jpg`}
        />
      </Head>
      <Box display="flex" flexDir="column">
        {content?.components?.map((component, index) => (
          <KustomComponent
            key={component.id}
            component={component}
            page={content}
            index={index}
          />
        ))}
      </Box>
    </>
  );
};

DynamicSlugPage.getLayout = (
  route: string,
  kustomPage?: KustomPage,
  pages?: KustomPage[],
) => {
  if (kustomPage?.model === 'room') {
    const Layout = ({ page }: layoutArgs) => (
      <RoomLayout route={route}>{page}</RoomLayout>
    );

    return Layout;
  }

  if (kustomPage?.model === 'joboffer') {
    const recruitmentPage = pages?.find((page) => page.model === 'joboffers');

    const Layout = ({ page }: layoutArgs) => (
      <ClosePageLayout
        route={route}
        getBackHref={(language) => recruitmentPage?.prettyUrl?.[language]}
      >
        {page}
      </ClosePageLayout>
    );

    return Layout;
  }
};

DynamicSlugPage.displayName = 'DynamicPage';

export default DynamicSlugPage;
